// $(function () {

  // メニューバーの固定
  (function () {
    if (window.matchMedia('(min-width: 1050px)').matches) {
      let $main = $('.l-subPage__wrap'),
        $nav = $('#c-nav'),
        navHeight = $nav.outerHeight(),
        offesetNav = $nav.offset().top,
        fixedClass = 'is-fixed';

      $(window).on('load scroll', function () {
        let value = $(this).scrollTop();
        if (value > offesetNav) {
          $nav.addClass(fixedClass);
          $main.css('margin-top', navHeight);
        } else {
          $nav.removeClass(fixedClass);
          $main.css('margin-top', '0');
        }
      });
    }
  })();

  //ハンバーガーメニュー・menuPrice特定リンク閉じる
  (function() {
    $('#js-buttonHamburger').click(function () {

      // $('body').toggleClass('is-drawerActive');
      $('#c-nav').fadeToggle();

      if ($(this).attr('aria-expanded') == 'false') {
        $(this).attr('aria-expanded', true);
      } else {
        $(this).attr('aria-expanded', false);
      }
    });
  })();

  (function() {
    $('#c-menuLink__close').click(function () {

      $('#c-nav').fadeToggle();

      let closeMenu = $('#js-buttonHamburger');

      if (closeMenu.attr('aria-expanded') == 'false') {
        closeMenu.attr('aria-expanded', true);
      } else {
        closeMenu.attr('aria-expanded', false);
      }
    });
  })();
  // スライドショーのフェードインアウト
  (function() {

    let $setElm = $('.p-slide__area'),
      fadeSpeed = 1800,
      switchDelay = 6500;

    $setElm.each(function () {

      let slideShow = $(this),
        slideUl = slideShow.find('ul'),
        slideLi = slideShow.find('li'),
        slideLiFirst = slideShow.find('li:first');

      slideLi.css({
        display: 'block', opacity: '0', zIndex: '99'
      });
      slideLiFirst.css({
        zIndex: '100'
      }).stop().animate({
        opacity: '1'
      }, fadeSpeed);

      setInterval(function () {

        slideUl.find('li:first-child').animate({
          opacity: '0'
        }, fadeSpeed).next('li').css({
          zIndex: '100'
        }).animate({
          opacity: '1'
        }, fadeSpeed).end().appendTo(slideUl).css({
          zIndex: '99'
        });
      }, switchDelay);
    });
  })();

/*subpageのみ実行 */
  $(window).bind("load", function () {
    if (!document.URL.match("index.html")) {
      if (window.matchMedia('(min-width: 768px)').matches) {

        /*sidebarの固定*/
        (function () {

          let content = $("#contents");
          let sidebar = $("#j-sidebar");
          let sticked = $("#sticked");
          // let navList = $(".c-menuList")
          let navList = $("#c-nav");

          // サイドバーの位置
          let sidebar_top = sidebar.offset().top;
          // 固定するコンテンツの元々の位置
          let sticked_original_top = sticked.offset().top;
          // 固定するコンテンツの高さ
          let sticked_height = sticked.height();
          //ヘッダー部の高さの取得
          let navHeight = navList.height();

          $(window).on('scroll load resize', function () { // スクロールかリサイズ時
            // 現在の位置
            let scrollTop = $(document).scrollTop();
            // メインコンテンツ最後尾
            let content_bottom = content.offset().top + content.height();

            if ((scrollTop > sticked_original_top - navHeight) && (scrollTop < content_bottom - sticked_height - navHeight)) {
              // 現在位置が、初期位置より下で、メインコンテンツ最後尾より上なら、画面上部にサイドバーを固定
              sticked.css({
                'position': 'fixed',
                'top': navHeight,
                'width': sidebar.width()
              });
            } else if (scrollTop >= content_bottom - sticked_height - navHeight) {
              // 現在位置がメインコンテンツ最後尾より下なら、メインコンテンツ最後尾にサイドバーを位置させる
              sticked.css({
                'position': 'absolute',
                'top': content_bottom - sticked_height - sidebar_top,
                'width': sidebar.width(),
              });
            } else {
              // 現在位置が初期位置より上なら、何もしない
              sticked.css({ 'position': 'static' });
            }
          });
        })();
      }
    }
  });
// });
